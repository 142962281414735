<template>
  <div class="header-wrapper">
    <!-- Header 文字版型 -->
    <div
      v-if="headerSection.enable_page_title || headerSection.enable_menu_items"
      class="s-header"
      :style="{ order: contentsOrder.page_title }"
    >
      <template v-if="headerSection.enable_menu_items">
        <div v-for="(i, idx) in headerSection.menu_items" :key="idx" :class="`s-header--${i.float} s-flex`">
          <!--LOGO-->
          <template v-if="i.type == 'meta.header_image'">
            <div v-if="headerLogo" class="s-header--logo">
              <img :src="headerLogo" />
            </div>
          </template>

          <!--Menu Items-->
          <template v-else>
            <!--Go Back-->
            <template v-if="i.type == 'go_back'">
              <a v-if="i.url == undefined" @click="$router.go(-1)">
                <icon :item="i" />
                {{ i.title }}
              </a>
              <router-link v-else-if="moduleMap.map((x) => x.type).includes(i.url)" :to="{
                name: moduleMap.find((x) => x.type == i.url).value,
                params: {
                  org_code: $route.params.orgCode || $route.params.org_code,
                  orgCode: $route.params.orgCode || $route.params.org_code,
                },
              }">
                <icon :item="i" />
                {{ i.title }}
              </router-link>
              <a v-else target="_blank" :href="i.url">
                <icon :item="i" />
                {{ i.title }}
              </a>
            </template>
            <template v-else>
              <a v-if="i.url != undefined" target="_blank" :href="i.url">
                <icon :item="i" />
                {{ i.title }}
              </a>
              <router-link v-else :to="moduleMap.map((x) => x.type).includes(i.type)
                  ? {
                    name: moduleMap.find((x) => x.type == i.type).value,
                    params: {
                      org_code:
                        $route.params.orgCode || $route.params.org_code,
                      orgCode:
                        $route.params.orgCode || $route.params.org_code,
                    },
                  }
                  : i.url
                ">
                <icon :item="i" />
                {{ i.title }}
              </router-link>
            </template>
          </template>
        </div>
      </template>
      <div v-if="headerSection.enable_page_title" class="s-header__title">
        {{ headerTitle }}
      </div>
    </div>

    <!-- Header 圖片版型 -->
    <div
      v-if="headerSection.enable_page_image" class="header-image"
      :style="{
        order: contentsOrder.page_image,
        paddingBottom: pageImageStyle['padding-bottom'],
        paddingTop: pageImageStyle['padding-top'],
      }"
    >
      <template v-if="headerImage"> <img :src="headerImage" :style="{width:pageImageStyle['width']}" /></template>
    </div>

    <img
      v-if="headerSection.enable_page_kv_image && pageKvImage"
      :src="pageKvImage"
      class="header-kv-image w-100"
      :style="{ order: contentsOrder.page_kv_image }"
    />
  </div>
</template>

<script>
import Vue from "vue";
import moduleMap from '@/mixins/liff/memberCenter/moduleMap'

const requireImage = (path) =>
  require(`@/assets/images/liff/module-icon/${path}`);

const icon = Vue.component("icon", {
  props: ["item"],
  render(h) {
    if (this.item.icon_url) {
      return h("img", { class: "s-header--icon", src: this.item.icon_url });
    } else {
      return h("img", {
        class: "s-header--type-icon",
        style: {
          maskImage: this.getIconMask(this.$props.item),
          webkitMaskImage: this.getIconMask(this.$props.item),
        },
      });
    }
  },
  methods: {
    getIcon(path) {
      try {
        return requireImage(path);
      } catch {
        return "";
      }
    },
    getIconMask(i) {
      return `url(${i.icon_url == undefined ? this.getIcon(`${i.type}.svg`) : i.icon_url
        })`;
    },
  },
});

export default {
  mixins:[moduleMap],
  components: {
    icon,
  },
  data() {
    return {

    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    pageImage: {
      type: String,
    },
    pageKvImage: {
      type: String,
    },
    meta: {
      type: Object,
    },
    title: {
      type: String,
    },
    showMemberCenter: {
      type: Boolean,
      default: true,
    },
    logo: {
      type: String,
    },
    headerSection: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    headerLogo() {
      return this.meta?.header_image || this.logo || "";
    },
    headerTitle() {
      if (this.pageTitle == "mata.title") return this.meta?.title;
      else return this.pageTitle || this.meta?.title || this.title || "";
    },
    headerImage() {
      if (this.pageImage == "mata.image") return this.meta?.image;
      else return this.pageImage || this.meta?.image || null;
    },
    contentsOrder() {
      return (this.headerSection?.contents || []).reduce((acc, cur) => {
        acc[cur.key] = cur.order
        return acc
      }, {})
    },
    pageImageStyle() {
      return this.headerSection?.page_image_style || {};
    }
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  display: flex;
  flex-direction: column;
}

.s-header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  img {
    max-height: 1rem;
  }

  &__title {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    color: var(--liff-header-text-color);
  }

  &--right {
    position: absolute;
    right: 12px;
    align-items: center;
    font-size: 14px;
    height: 100%;
  }

  &--left {
    position: absolute;
    left: 12px;
    align-items: center;
    font-size: 14px;
    height: 100%;
  }

  a {
    text-decoration: none;
    margin-left: 4px;
    display: flex;
    align-items: center;

  }

  &--icon {
    max-height: 1rem;
  }

  &--type-icon {
    max-height: 1rem;
    width: 1rem;
    height: 1rem;
    background-color: var(--liff-header-text-color);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
  }

  &--logo {
    height: 100%;

    img {
      height: 100%;
      max-height: 100%;
    }
  }
}

.header-image:not(:empty) {
  text-align: center;
  padding: 0;
  background-color: var(--liff-header-image-bg-color);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    //max-height: 100px;
    max-width: 100%;
  }
}
</style>
